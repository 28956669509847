import React, { useState, useCallback, useMemo } from "react";
import {
  Button,
  SvgIcon,
  Grid,
  TextField,
  InputAdornment,
  Tooltip,
} from "@mui/material";
import PageHeader from "../../common/PageHeader/index";
import { useNavigate } from "react-router-dom";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import LayersIcon from "@mui/icons-material/Layers";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Search from "@mui/icons-material/Search";
import SettingsIcon from "@mui/icons-material/Settings";
import OrderColumnSetup from "./components/OrderColumnSetup";
import OrderFilterForm from "./OrderFilterForm";
import ActionModal from "../../common/Actions/ActionModal";
import { ModalType } from "../../../utils/contants/Modal/modal";
const HeaderButton = ({ icon, onClick, disabled, children }) => {
  const svg = <SvgIcon component={icon} inheritViewBox fontSize="small" />;

  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      variant="contained"
      color="primary"
      size="small"
      startIcon={svg}
    >
      {children}
    </Button>
  );
};

function OrderHeader({
  handleOpenModal,
  handleOpenModalWithValues,
  allOrders,
  searchQuery,
  onSearchQueryChange,
  selectedOrders,
}) {
  const [showModal, setShowModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const [showFilter, setShowFilter] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const navigate = useNavigate();
  const handleActionsClick = (event) => {
    setAnchorEl(event.currentTarget);
    setShowModal(true);
  };

  const toggleFilter = useCallback(() => {
    setShowFilter((prev) => !prev);
  }, []);

  const toggleSettings = useCallback(() => {
    setShowSettings((prev) => !prev);
  }, []);

  const handleNavigateCreate = useCallback(() => {
    navigate("/order/create");
  }, [navigate]);

  const handleSearchChange = useCallback(
    (e) => {
      onSearchQueryChange(e.target.value);
    },
    [onSearchQueryChange]
  );

  // Memoize expensive calculation
  const canExport = useMemo(() => {
    return Object.values(allOrders).some(
      (budget) => Object.keys(budget || {}).length > 0
    );
  }, [allOrders]);

  const ExpandIcon = showFilter ? ExpandLessIcon : ExpandMoreIcon;
  return (
    <>
      <PageHeader
        title=""
        noBorder={false}
        action={
          <Grid container spacing={1} justifyContent={["space-between", ""]}>
            <Grid item xs={12} md={5} xl={4.5} display={"flex"} gap={1}>
              <TextField
                placeholder="Search orders..."
                variant="filled"
                hiddenLabel
                value={searchQuery}
                onChange={(e) => {
                  handleSearchChange(e);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                  disableUnderline: true,
                }}
                fullWidth
                size="small"
              />
              <Button
                variant="outlined"
                sx={{
                  padding: "0 1rem",
                }}
                onClick={toggleFilter}
                size="small"
                endIcon={<ExpandIcon />}
              >
                Filter
              </Button>
              <SettingsIcon
                onClick={toggleSettings}
                sx={{ cursor: "pointer" }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={5}
              xl={4.5}
              display="flex"
              gap={1}
              justifyContent={["space-between", "flex-end"]}
              alignItems="flex-end"
            >
              <Tooltip
                title={
                  selectedOrders?.length === 0
                    ? "Select order/s to perform actions"
                    : ""
                }
              >
                <span>
                  <HeaderButton
                    onClick={handleActionsClick}
                    icon={LayersIcon}
                    disabled={selectedOrders?.length === 0}
                  >
                    Actions
                  </HeaderButton>
                </span>
              </Tooltip>
              {showModal && (
                <ActionModal
                  open={showModal}
                  handleClose={() => {
                    setShowModal(false);
                    setAnchorEl(null);
                  }}
                  anchorEl={anchorEl}
                  actions={[
                    {
                      name: "Export",
                      description: "Export all orders to CSV",
                      action: () => {
                        handleOpenModal(ModalType.EXPORT);
                      },
                    },
                    {
                      name: "Change Status",
                      description: "Change the status of selected orders",
                      action: () => {
                        handleOpenModal("statusUpdate");
                      },
                    },
                  ]}
                />
              )}
              <HeaderButton
                onClick={handleNavigateCreate}
                icon={AddCircleOutlineIcon}
              >
                Create Order
              </HeaderButton>
            </Grid>
          </Grid>
        }
      />
      {showFilter && <OrderFilterForm toggleFilter={toggleFilter} />}
      {showSettings && <OrderColumnSetup />}
    </>
  );
}

export default OrderHeader;

// export default madProps(OrderHeader, {
//   allOrders: useAllBudgets,
// })

