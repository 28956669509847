import { Box, Button } from "@mui/material";
import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { gql, useMutation } from "@apollo/client";
import { useNotification } from "../../../services/hooks/useNotification";

const UPLOAD_FILE = gql`
  mutation ($file: Upload!) {
    uploadFile(file: $file) {
      success
    }
  }
`;

const Upload = ({ onSuccess }) => {
  const [file, setFile] = React.useState(null);
  const [fileToUpload, setFileToUpload] = React.useState(null);
  const { notify } = useNotification();
  const [uploadFile] = useMutation(UPLOAD_FILE);

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const selectedFile = acceptedFiles[0];
      setFile(selectedFile.name);
      setFileToUpload(selectedFile); // Store the single File object directly
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "text/csv": [".csv"],
      "text/plain": [".txt"],
      "text/json": [".json"],
    },
  });

  const handleUpload = () => {
    if (!fileToUpload) {
      notify({ message: "Please select a file to upload", type: "error" });
      return;
    }

    uploadFile({
      variables: {
        file: fileToUpload, // Pass the single File object directly
      },
    })
      .then((res) => {
        notify("File uploaded successfully", "success");
        setFile(null); // Reset after successful upload
        setFileToUpload(null);
        onSuccess();
      })
      .catch((error) => {
        notify(error.message, "error");
      });
  };

  return (
    <section>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        {!file ? (
          <Box
            border={"1px dashed #ccc"}
            p={2}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <h6>Drag 'n' drop a file here, or click to select a file</h6>
            <h4>(Only *.json and *.csv files will be accepted)</h4>
          </Box>
        ) : (
          <h5>{file}</h5>
        )}
      </div>

      {file && (
        <Button
          variant="contained"
          color="primary"
          onClick={handleUpload}
          disabled={!file}
        >
          Upload
        </Button>
      )}
    </section>
  );
};

export default Upload;
