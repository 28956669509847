import React from "react";
import DataSection from "../../common/Lists/Details/DataSection";
import VendorAutocomplete from "../../vendor/VendorAutocomplete";
import { Button, CircularProgress, Box } from "@mui/material";
import DetailModal from "../../common/Lists/DetailModal";
import { FormProvider, useForm } from "react-hook-form";
import { useMutateBrand } from "../hooks/useMutateBrand";
import EditIcon from "@mui/icons-material/Edit";
function BrandDetail({ handleClose, defaultValues, isUpdate = true }) {
  const { mutateBrand, loading } = useMutateBrand(handleClose);

  const methods = useForm({
    defaultValues: defaultValues.data,
  });
  const {
    control,
    handleSubmit,
    formState: { isValid, isDirty, isSubmitting },
  } = methods;

  const submitCallback = handleSubmit(async (data) => {
    //create vendor here
    try {
      await mutateBrand(data, isUpdate);
    } catch (e) {
      console.log(e);
    }
  });

  const onSubmit = (e) => {
    e.stopPropagation();
    submitCallback(e);
  };

  return (
    <DetailModal title={defaultValues.data.name} handleClose={handleClose}>
      <FormProvider {...methods}>
        <form onSubmit={onSubmit}>
          {/* <Typography variant="label">Name: {defaultValues.data.name}</Typography>
      <Typography variant="label">Vendor: {defaultValues.data.vendor?.name}</Typography> */}
          <DataSection
            title="Brand Details"
            fields={[
              {
                name: "name",
                label: "Name",
                rules: { required: "Name is required" },
              },
              {
                name: "vendor",
                label: "Vendor",
                rules: { required: "Vendor is required" },
                component: <VendorAutocomplete />,
              },
            ]}
            control={control}
          />
          <Box
            sx={{ mt: 3, display: "flex", justifyContent: "flex-end", gap: 2 }}
          >
            <Button
              variant="contained"
              data-testid="cancel-btn"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              data-testid="save-btn"
              type="submit"
              variant="contained"
              disabled={!isDirty}
              endIcon={
                isSubmitting ? <CircularProgress size={10} /> : <EditIcon />
              }
              disableElevation
            >
              Submit
            </Button>
          </Box>
        </form>
      </FormProvider>
    </DetailModal>
  );
}

export default BrandDetail;
