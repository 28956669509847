import React from "react";
import Datasection from "../../common/Lists/Details/DataSection";

function VendorForm({ control }) {
  return (
    <>
      <Datasection
        title="Vendor Details"
        fields={[
          {
            name: "name",
            label: "Name",
            rules: { required: "Name is required" },
          },
          {
            name: "email",
            label: "Email",
            rules: { required: "Email is required" },
          },
        ]}
        control={control}
      />
      <Datasection
        title="Address"
        fields={[
          {
            name: "address.street",
            label: "Address",
          },
          {
            name: "address.city",
            label: "City",
          },
          {
            name: "address.province",
            label: "Province",
          },
          {
            name: "address.zipCode",
            label: "ZIP Code",
          },
          {
            name: "address.country",
            label: "Country",
          },
        ]}
        control={control}
      />
      {/* <Datasection
        title="Notes"
        fields={[{ name: "notes", label: "Notes" }]}
        control={control}
      /> */}
    </>
  );
}

export default VendorForm;
