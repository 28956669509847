import { gql } from "@apollo/client";
import { Item } from "./query";
export const CREATE_ITEM = gql`
  mutation createItem($input: CreateItemInput!) {
    createItem(input: $input) {
      id
      name
      description
      size
      strain
      store {
        id
      }
      category {
        id
        name
      }
    }
  }
`;

export const UPDATE_ITEM = gql`
  mutation updateItem($input: UpdateItemInput!) {
    updateItem(input: $input) {
      ...Item
    }
  }
  ${Item}
`;
