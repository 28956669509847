import { useMutation } from "@apollo/client";
import { CREATE_BRAND, UPDATE_BRAND } from "../../../graphql/brands/mutation";
import { GET_BRANDS } from "../../../graphql/brands/query";
import { useNotification } from "../../../services/hooks/useNotification";
import useAuthStore from "../../../store/authStore";

export const useMutateBrand = (handleClose) => {
  const { currentStore } = useAuthStore();
  const { notify } = useNotification();

  const [createBrand, { loading: createLoading }] = useMutation(CREATE_BRAND, {
    onCompleted: () => {
      notify("Brand added successfully", "success");
      handleClose();
    },
    onError: (error) => {
      notify(`Failed to add brand: ${error.message}`, "error");
    },
    refetchQueries: [GET_BRANDS],
  });

  const [updateBrand, { loading: updateLoading }] = useMutation(UPDATE_BRAND, {
    onCompleted: () => {
      notify("Brand updated successfully", "success");
      handleClose();
    },
    onError: (error) => {
      notify(`Failed to update brand: ${error.message}`, "error");
    },
    refetchQueries: [GET_BRANDS],
  });

  const mutateBrand = async (input, isUpdate = false) => {
    const brand = {
      id: input.id,
      name: input.name,
      vendorId: input.vendor,
      storeId: currentStore?.id,
    };

    const mutation = isUpdate ? updateBrand : createBrand;
    try {
      const { data } = await mutation({
        variables: { input: brand },
      });
      return isUpdate ? data.updateBrand : data.createBrand;
    } catch (error) {
      console.error("Mutation error:", error);
      throw error;
    }
  };

  return {
    mutateBrand,
    loading: createLoading || updateLoading,
  };
};
