import { useQuery } from "@apollo/client";
import { useNotification } from "../../../services/hooks/useNotification";

const useQueryWrapper = (query, options = {}) => {
  const { notify } = useNotification();

  return useQuery(query, {
    ...options,
    onError: (error) => {
      if (error.graphQLErrors) {
        error.graphQLErrors.forEach((err) => {
          if (err.extensions?.code === "UNAUTHENTICATED") {
            notify({
              message: "Session expired. Please log in again.",
              type: "error",
            });
          } else {
            notify(`Error: ${err.message}`, "error");
          }
        });
      } else if (error.networkError) {
        notify("Network error. Please check your connection.", "error");
      }

      if (options.onError) {
        options.onError(error);
      }
    },
  });
};

export default useQueryWrapper;
