import { gql } from "@apollo/client";
import { Brand } from "./fragment";

export const CREATE_BRAND = gql`
  mutation createBrand($input: CreateBrandInput!) {
    createBrand(input: $input) {
      ...Brand
    }
  }
  ${Brand}
`;

export const UPDATE_BRAND = gql`
  mutation updateBrand($input: UpdateBrandInput!) {
    updateBrand(input: $input) {
      ...Brand
    }
  }
  ${Brand}
`;
