import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { isFuture, isValid, startOfDay } from "date-fns";
// If you are using date-fns v3.x or v4.x, please import the v3 adapter
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
function DateField({
  name,
  label,
  rules,
  defaultValue,
  deps,
  disableFuture = false,
  validate,
}) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={{
        deps,
        validate: (val) => {
          if (!val) {
            return;
          }

          if (!isValid(val)) {
            return "Invalid date";
          }

          // Compare days using `startOfDay` to ignore timezone offset
          if (disableFuture) {
            console.log(
              "Date is in the future:",
              isFuture(startOfDay(val)),
              startOfDay(val),
              val
            );
            return isFuture(startOfDay(val))
              ? "Date cannot be in the future"
              : undefined;
          }

          return validate?.(val);
        },
      }}
      render={({ field, fieldState }) => (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            // className={inputCss.input}
            label={label}
            inputFormat="dd/MM/yyyy"
            {...field}
            disableFuture={disableFuture}
            renderInput={({ label, error: _, ...params }) => (
              <TextField
                label={fieldState.error?.message || label}
                {...params}
                fullWidth
                error={!!fieldState.error}
              />
            )}
            PaperProps={{
              elevation: 2,
            }}
          />
        </LocalizationProvider>
      )}
    />
  );
}

export default DateField;
