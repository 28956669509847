import React, { useState, useMemo, useEffect, useCallback } from "react";
import { useGetOrders } from "./hooks/useGetOrders";
import OrderHeader from "./list/OrderHeader";
import OrderDetailsDialog from "./list/OrderDetailsDialog";
import useAuthStore from "../../store/authStore";
import OrdersTable from "./list/OrdersTable";
import useOrderListFilterStore from "../../store/orderListFIlterSettingsStore";
import { FormProvider, useForm } from "react-hook-form";
import ExportDialog from "../common/Lists/ExportDialog";
import OrderStatusUpdate from "./list/components/OrderStatusUpdate";
const ModalType = {
  ENTRY: "entry",
  REMOVE: "remove",
  DETAILS: "details",
  EDIT: "edit",
  EXPORT: "export",
  STATUSUPDATE: "statusUpdate",
};

const defaultOpen = {
  [ModalType.ENTRY]: false,
  [ModalType.REMOVE]: false,
  [ModalType.DETAILS]: false,
  [ModalType.EDIT]: false,
  [ModalType.EXPORT]: false,
  [ModalType.STATUSUPDATE]: false,
};

const coloumnsForCsvExport = [
  "number",
  "store",
  "vendor",
  "totalAmount",
  "status",
];

function Orders() {
  const formMethods = useForm({
    mode: "onChange",
  });
  const [searchQuery, setSearchQuery] = useState("");
  const { filter } = useOrderListFilterStore();

  const { orders, loading } = useGetOrders(filter?.values);
  const [defaultValues, setDefaultValues] = useState(undefined);
  const [open, setOpen] = useState(defaultOpen);

  const filteredEntries = useMemo(() => {
    if (!searchQuery) {
      return orders;
    }

    const query = searchQuery.toLowerCase();
    return orders.filter((order) => {
      const {
        number,
        vendor: { name },
      } = order;

      return (
        number.toString().includes(query) ||
        // id.toLowerCase().includes(query) ||
        name.toLowerCase().includes(query)
      );
    });
  }, [orders, searchQuery]);

  const { getValues, watch } = formMethods;

  const handleClose = () => {
    setOpen(defaultOpen);
    setDefaultValues(undefined);
  };
  const handleOpenModal = (type) => {
    setOpen((prev) => ({ ...prev, [type]: true }));
  };
  const handleOpenModalWithValues = (modal, data) => {
    setDefaultValues(data);
    handleOpenModal(modal)();
  };
  const selected = watch("selectedItems");
  console.log(selected);

  const selectedOrder = useMemo(() => {
    return Object.values(selected || {});
  }, [selected]);

  console.log("selectedOrder", selectedOrder);
  return (
    <>
      <OrderHeader
        handleOpenModal={handleOpenModal}
        handleOpenModalWithValues={() => {}}
        allOrders={orders || []}
        searchQuery={searchQuery}
        onSearchQueryChange={setSearchQuery}
        selectedOrders={selectedOrder}
      />
      <FormProvider {...formMethods}>
        <form>
          <OrdersTable
            handleViewDetail={(order) =>
              handleOpenModalWithValues(ModalType.DETAILS, order)
            }
            allSelected={getValues("select")}
            loading={loading}
            list={filteredEntries}
          />
          {open[ModalType.STATUSUPDATE] && (
            <OrderStatusUpdate
              handleClose={handleClose}
              ordersToUpdate={selected || {}}
            />
          )}
        </form>
      </FormProvider>

      {open[ModalType.DETAILS] && (
        <OrderDetailsDialog
          handleClose={handleClose}
          defaultValues={defaultValues}
        />
      )}

      {open[ModalType.EXPORT] && selectedOrder.length > 0 && (
        <ExportDialog
          handleClose={handleClose}
          defaultValues={selectedOrder}
          coloumnsForCsvExport={coloumnsForCsvExport}
          name="Orders"
          isExportOrders={true}
        />
      )}
    </>
  );
}

export default Orders;
