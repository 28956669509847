import React, { useState, useCallback, useMemo } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import classNames from "classnames";
import { Checkbox } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

import css from "./styles.module.css";
import { Collapse } from "@mui/material";

const descendingComparator = (a, b, orderBy) => {
  if (!a.cells[orderBy] || !b.cells[orderBy]) return 0;
  return b.cells[orderBy].rawValue > a.cells[orderBy].rawValue ? -1 : 1;
};

const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const EnhancedTableHead = ({ headCells, order, orderBy, onRequestSort }) => {
  const { control } = useFormContext();
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            sortDirection={orderBy === headCell.id ? order : false}
            sx={headCell.width ? { width: headCell.width } : undefined}
            className={classNames({ sticky: headCell.sticky })}
          >
            {headCell.id === "select" ? (
              <Controller
                name="select"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    checked={field.value}
                    onChange={(event) => field.onChange(event.target.checked)}
                  />
                )}
              />
            ) : (
              headCell.label && (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={() => onRequestSort(headCell.id)}
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              )
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const pageSizes = [10, 25, 100];

const EnhancedTable = React.memo(function EnhancedTable({
  custom,
  rows,
  headCells,
  mobileVariant,
}) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(pageSizes[1]);

  const handleRequestSort = useCallback(
    (property) => {
      setOrder((prevOrder) => {
        const isAsc = orderBy === property && prevOrder === "asc";
        return isAsc ? "desc" : "asc";
      });
      setOrderBy(property);
    },
    [orderBy]
  );

  const handleChangePage = useCallback((_, newPage) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback((event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  const sortedAndPagedRows = useMemo(() => {
    const orderedRows = orderBy
      ? rows.slice().sort(getComparator(order, orderBy))
      : rows;
    return orderedRows.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
  }, [rows, order, orderBy, page, rowsPerPage]);

  return (
    <Box>
      <TableContainer
        data-testid="table-container"
        component={Paper}
        sx={{ width: "100%", mb: 2 }}
      >
        <Table
          aria-labelledby="tableTitle"
          className={mobileVariant ? css.mobileColumn : undefined}
        >
          <EnhancedTableHead
            headCells={headCells}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {sortedAndPagedRows.length > 0 ? (
              sortedAndPagedRows.map((row, index) => (
                <TableRow
                  tabIndex={-1}
                  key={row.key ?? index}
                  selected={row.selected}
                  className={classNames({
                    [css.collapsedRow]: row.collapsed,
                  })}
                >
                  {Object.entries(row.cells).map(([key, cell]) => (
                    <TableCell
                      key={key}
                      className={classNames({
                        sticky: cell.sticky,
                        [css.collapsedCell]: row.collapsed,
                      })}
                      style={{
                        padding: "10px",
                      }}
                    >
                      <Collapse key={index} in={!row.collapsed} enter={false}>
                        {cell.content}
                      </Collapse>
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {rows.length > sortedAndPagedRows.length && (
        <TablePagination
          rowsPerPageOptions={pageSizes}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Box>
  );
});

export default EnhancedTable;
