import { create } from "zustand";
import { persist } from "zustand/middleware";
import StatusChip from "../components/common/StatusChip";
import { Checkbox } from "@mui/material";

const allColumns = [
  {
    id: "select",
    label: "",
    render: (row) => (
      <Checkbox
        checked={row.selected}
        onChange={() => {
          console.log("Checkbox clicked");
        }}
      />
    ),
  },
  { id: "number", label: "PO#", render: (row) => row },
  { id: "vendor", label: "Vendor", render: (row) => row?.name },
  { id: "store", label: "Store", render: (row) => row?.name },
  {
    id: "status",
    label: "Status",
    render: (row) => <StatusChip status={row} />,
  },
  {
    id: "orderDate",
    label: "Order Date",
    render: (row) => new Date(Number(row)).toLocaleDateString(),
  },
  {
    id: "expecteddate",
    label: "Expected Date",
    render: (row) => (row ? new Date(Number(row)).toLocaleDateString() : "-"),
  },
  {
    id: "receiptDate",
    label: "Receipt Date",
    render: (row) => (row ? new Date(Number(row)).toLocaleDateString() : "-"),
  },
  { id: "invoiceNumber", label: "Invoice#" },
  { id: "totalAmount", label: "Total Amount" },
];

const defaultColumnIds = [
  "number",
  "status",
  "store",
  "orderDate",
  "expecteddate",
  "totalAmount",
];

// Utility to restore the render functions
const restoreRenderFunctions = (columns) => {
  return columns.map((column) => {
    const originalColumn = allColumns.find((col) => col.id === column.id);
    return {
      ...column,
      render: originalColumn?.render, // Reapply the render function from the original columns
    };
  });
};

const useColumnStateStore = create(
  persist(
    (set, get) => ({
      columns: restoreRenderFunctions(
        allColumns.map((column) => ({
          ...column,
          selected: defaultColumnIds.includes(column.id),
        }))
      ),
      toggleColumn: (columnId) =>
        set((state) => ({
          columns: restoreRenderFunctions(
            state.columns.map((column) =>
              column.id === columnId
                ? { ...column, selected: !column.selected }
                : column
            )
          ),
        })),
      resetColumns: () =>
        set((state) => ({
          columns: restoreRenderFunctions(
            state.columns.map((column) => ({
              ...column,
              selected: defaultColumnIds.includes(column.id),
            }))
          ),
        })),
      selectAllColumns: () =>
        set((state) => ({
          columns: restoreRenderFunctions(
            state.columns.map((column) => ({
              ...column,
              selected: true,
            }))
          ),
        })),
      deselectAllColumns: () =>
        set((state) => ({
          columns: restoreRenderFunctions(
            state.columns.map((column) => ({
              ...column,
              selected: false,
            }))
          ),
        })),
    }),
    {
      name: "column-state-storage",
      getStorage: () => localStorage,
      // Deserialize the state to restore render functions
      onRehydrateStorage: () => (state) => {
        if (state) {
          state.columns = restoreRenderFunctions(state.columns);
        }
      },
    }
  )
);

export default useColumnStateStore;
