import { useMutation } from "@apollo/client";
import {
  CREATE_VENDOR,
  UPDATE_VENDOR,
} from "../../../graphql/vendors/mutation";
import { GET_VENDORS } from "../../../graphql/vendors/query";
import { useNotification } from "../../../services/hooks/useNotification";
import useAuthStore from "../../../store/authStore";

export const useMutateVendor = (handleClose) => {
  const { currentStore } = useAuthStore();
  const { notify } = useNotification();

  const [createVendor, { loading: createLoading }] = useMutation(
    CREATE_VENDOR,
    {
      onCompleted: () => {
        notify("Successfully added vendor", "success");
        handleClose();
      },
      onError: (error) => {
        notify("Failed to add vendor", "error");
      },
      update: updateCache,
    }
  );

  const [updateVendor, { loading: updateLoading }] = useMutation(
    UPDATE_VENDOR,
    {
      onCompleted: () => {
        notify("Successfully updated vendor", "success");
        handleClose();
      },
      onError: (error) => {
        notify("Failed to update vendor", "error");
      },
      update: updateCache,
    }
  );

  const mutateVendor = async (input, isUpdate = false) => {
    input.storeId = currentStore?.id;
    delete input.__typename;
    delete input.createdAt;
    delete input.address.__typename;
    //modify address to be json object

    const mutation = isUpdate ? updateVendor : createVendor;

    const { data } = await mutation({
      variables: { input },
    });

    return isUpdate ? data.updateVendor : data.createVendor;
  };

  function updateCache(cache, { data }) {
    const newVendor = data.createVendor || data.updateVendor;
    const existingData = cache.readQuery({
      query: GET_VENDORS,
      variables: {
        filter: { name: "" },
        sort: { field: "name", order: "ASC" },
      },
    });

    if (existingData) {
      const updatedVendors = data.createVendor
        ? [...existingData.vendors, newVendor]
        : existingData.vendors.map((v) =>
            v.id === newVendor.id ? newVendor : v
          );

      cache.writeQuery({
        query: GET_VENDORS,
        data: { vendors: updatedVendors },
        variables: {
          filter: { name: "" },
          sort: { field: "name", order: "ASC" },
        },
      });
    }
  }

  return {
    mutateVendor,
    loading: createLoading || updateLoading,
  };
};
