import { gql } from "@apollo/client";
import { vendor } from "./query";
export const CREATE_VENDOR = gql`
  mutation createVendor($input: CreateVendorInput!) {
    createVendor(input: $input) {
      ...Vendor
    }
  }
  ${vendor}
`;

export const UPDATE_VENDOR = gql`
  mutation updateVendor($input: UpdateVendorInput!) {
    updateVendor(input: $input) {
      ...Vendor
    }
  }
  ${vendor}
`;
