import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
} from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import SendIcon from "@mui/icons-material/Send";
import ModalDialog from "../../common/ModalDialog/index";
import { useMutateVendor } from "../hooks/useMutateVendor";
import VendorForm from "./VendorForm";

function CreateVendor({ handleClose, defaultValues }) {
  const { mutateVendor, loading: submitting } = useMutateVendor(handleClose);
  const methods = useForm({
    defaultValues,
    mode: "onChange",
  });

  const { handleSubmit, formState, control } = methods;

  const submitCallback = handleSubmit(async (data) => {
    //create vendor here
    try {
      await mutateVendor(data);
    } catch (e) {
      console.log(e);
    }
  });

  const onSubmit = (e) => {
    e.stopPropagation();
    submitCallback(e);
  };

  return (
    <ModalDialog
      open
      onClose={handleClose}
      dialogTitle={defaultValues?.amount ? "Edit entry" : "Create entry"}
    >
      <FormProvider {...methods}>
        <form onSubmit={onSubmit}>
          <DialogContent>
            <VendorForm control={control} />
          </DialogContent>

          <DialogActions
            sx={{
              display: "flex",
              marginRight: 2,
              marginBottom: 2,
            }}
          >
            <Button
              variant="contained"
              data-testid="cancel-btn"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              data-testid="save-btn"
              type="submit"
              variant="contained"
              disabled={!formState.isValid}
              endIcon={
                formState.isSubmitting || submitting ? (
                  <CircularProgress size={15} />
                ) : (
                  <SendIcon />
                )
              }
              disableElevation
            >
              Submit
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </ModalDialog>
  );
}

export default CreateVendor;
