import React from "react";
import Datasection from "../../common/Lists/Details/DataSection";
import CategoryAutoComplete from "../../categories/CategoryAutoComplete";
import { Grid, TextField as MuiTextField } from "@mui/material";
import BrandsAutocomplete from "../../brand/BrandsAutocomplete";
import TextField from "../../common/TextField";
function ItemForm({ control }) {
  return (
    <>
      <Datasection
        title="Item Details"
        fields={[
          {
            name: "name",
            label: "Name",
            rules: { required: "Name is required" },
          },
          {
            name: "category",
            label: "Category",
            rules: { required: "Category is required" },
            component: <CategoryAutoComplete />,
          },
        ]}
        control={control}
      />
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} md={4}>
          <BrandsAutocomplete />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField label="Size" name="size" />{" "}
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField label="Strain" name="strain" />
        </Grid>
      </Grid>

      <Datasection
        title="Description"
        fields={[
          {
            name: "description",
            label: "Description",
            rules: { required: "Description is required" },
            component: (
              <MuiTextField
                name="description"
                label={"Description"}
                multiline
                rows={2}
                sx={{ width: "100%" }}
              />
            ),
          },
        ]}
        control={control}
      />
    </>
  );
}

export default ItemForm;
