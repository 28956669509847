import React, { useState, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Autocomplete as MUIAutoComplete, TextField } from "@mui/material";
import Loader from "../common/Loader.jsx";

function AutoComplete({ name, label, data, loading, value = null }) {
  const [options, setOptions] = useState([]);
  const {
    control,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    if (!loading && data) {
      setOptions(data);
    }
  }, [data, loading]);
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <MUIAutoComplete
          disablePortal
          getOptionLabel={(option) => {
            // Handle both object and ID cases
            if (typeof option === "object") return option.name;
            // Find the vendor by ID and return its name
            return options.find((op) => op.id === option)?.name || "";
          }}
          options={!loading ? options : []}
          value={field.value || null}
          isOptionEqualToValue={(option, value) => option.id === value}
          onChange={(_, data) => field.onChange(data?.id)}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              error={Boolean(errors.label)}
              helperText={errors.label?.message}
              InputProps={{
                ...params.InputProps,
                sx: {
                  backgroundColor: "#D9E9ED",
                },
                endAdornment: (
                  <React.Fragment>
                    {loading ? <Loader color="inherit" size={10} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      )}
      rules={{
        required: {
          value: true,
          message: "*",
        },
      }}
    />
  );
}

export default AutoComplete;
