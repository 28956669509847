import { useState, useMemo } from "react";
import Lists from "../common/Lists";
import ListHeader from "../common/Lists/ListsHeader";
import { defaultOpen, ModalType } from "../../utils/contants/Modal/modal";
import CreateCategory from "./CreateCategory";
import CategoryDetail from "./CategoryDetail";
import ExportDialog from "../common/Lists/ExportDialog";
import { useCategories } from "./hooks/useCategories";
import { FormProvider, useForm } from "react-hook-form";
import FullPageSpinner from "../common/FullPage";

const coloumnsForCsvExport = ["name", "masterCategory"];
const columns = [
  {
    id: "name",
    label: "Name",
  },
];

export default function CategoriesList() {
  const { categories, loading } = useCategories();
  const [searchQuery, setSearchQuery] = useState("");
  const [defaultValues, setDefaultValues] = useState(undefined);
  const [open, setOpen] = useState(defaultOpen);
  const formMethods = useForm({
    mode: "onChange",
  });

  const filteredEntries = useMemo(() => {
    if (!searchQuery) {
      return categories;
    }

    const query = searchQuery.toLowerCase();
    return categories.filter((order) => {
      const { name } = order;

      return name.toLowerCase().includes(query);
    });
  }, [categories, searchQuery]);

  const handleClose = () => {
    setOpen(defaultOpen);
    setDefaultValues(undefined);
  };
  const handleOpenModal = (type) => () => {
    setOpen((prev) => ({ ...prev, [type]: true }));
  };
  const handleOpenModalWithValues = (modal, data) => {
    setDefaultValues(data);
    handleOpenModal(modal)();
  };

  return (
    <>
      <ListHeader
        handleOpenModal={handleOpenModal}
        handleOpenModalWithValues={handleOpenModalWithValues}
        title="Categories"
        allListItems={categories ?? []}
        searchQuery={searchQuery}
        onSearchQueryChange={setSearchQuery}
      />
      {loading ? (
        <FullPageSpinner />
      ) : (
        <FormProvider {...formMethods}>
          <form>
            <Lists
              handleViewDetail={(data) =>
                handleOpenModalWithValues(ModalType.DETAILS, { data })
              }
              loading={loading}
              list={filteredEntries ?? []}
              label="Categories"
              columns={columns}
            />
          </form>
        </FormProvider>
      )}

      {open[ModalType.ENTRY] && (
        <CreateCategory
          handleClose={handleClose}
          defaultValues={defaultValues}
        />
      )}

      {open[ModalType.DETAILS] && (
        <CategoryDetail
          handleClose={handleClose}
          defaultValues={defaultValues}
        />
      )}

      {open[ModalType.EXPORT] && (
        <ExportDialog
          handleClose={handleClose}
          defaultValues={defaultValues}
          coloumnsForCsvExport={coloumnsForCsvExport}
          name="Categories"
        />
      )}
    </>
  );
}
