import * as React from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import useColumnStateStore from "../../../../store/columnStateStore";

export default function OrderColumnSetup() {
const { columns, toggleColumn}= useColumnStateStore();


  return (
    <FormGroup
      sx={{
        position: "sticky",
        margin: 2,
        display: "flex",
        flexDirection: "row",
      }}
    >
      {columns.map((column) => (
        <FormControlLabel
          key={column.id}
          control={
            <Checkbox
              checked={column.selected}
              onChange={() => {
                toggleColumn(column.id);
              }}
            />
          }
          label={column.label}
        />
      ))}
    </FormGroup>
  );
}
