import React from "react";
import { DialogContent } from "@mui/material";
import ModalDialog from "../../common/ModalDialog/index";
import Upload from "../../common/Dropzone/index";

function ImportBrand({ handleClose, defaultValues }) {
  return (
    <ModalDialog open onClose={() => handleClose()} dialogTitle="Import Brands">
      <DialogContent>
        <Upload onSuccess={() => handleClose()} />
      </DialogContent>
    </ModalDialog>
  );
}

export default ImportBrand;
