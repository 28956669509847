import useQueryWrapper from "../../common/hooks/useQueryWrapper";
import { GET_ORDERS } from "../../../graphql/order/query";
import { useMemo } from "react";

export const useGetOrders = (filters) => {

  const stores = useMemo(() => {
    if (!filters.store) return [];
    return filters.store.map((store) => store.id);
  }, [filters.store]);

  const { loading, error, data } = useQueryWrapper(GET_ORDERS, {
    variables: {
      filter: {
        storeId: JSON.stringify(stores),
        status: JSON.stringify(filters.status),
        orderDate: null,
      },
      sort: {
        field: "name",
        order: "ASC",
      },
    },
  });

  // Return the necessary variables and functions
  return { loading, error, orders: data?.orders };
};
