import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";

const ITEM_HEIGHT = 18;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, option, theme) {
  return {
    fontWeight: option.includes(name)
      ? theme.typography.fontWeightMedium
      : theme.typography.fontWeightRegular,
  };
}

export default function MultipleSelectChip({
  options,
  name,
  value,
  onChange,
  placeholder = "Select options",
  multiple = true,
}) {
  const theme = useTheme();

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    event.stopPropagation();
    const newValue = typeof value === "string" ? value.split(",") : value;
    onChange(newValue);
  };
  const handleDelete = (valueToDelete, event) => {
    event.stopPropagation();

    const newValue = value.filter((item) => item !== valueToDelete);
    onChange(newValue); // Update the form state
  };

  const filterOptions = (item) => {
    if (typeof item === "object") {
      const valueSelected = value.find((val) => item.id === val.id);
      if (valueSelected) {
        return null;
      }
      return item;
    }
    return !value?.includes(item) ? item : null;
  };

  return (
    <div>
      <Select
        labelId="multiple-chip-label"
        id="multiple-chip"
        displayEmpty
        multiple={multiple}
        size="small"
        name={name}
        value={value}
        onChange={handleChange}
        renderValue={(value) => {
          if (value.length === 0) {
            return <span>{placeholder}</span>;
          }

          return (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {value.map((val, index) => (
                <Chip
                  key={index}
                  label={val?.name || val}
                  onDelete={(event) => handleDelete(val, event)}
                  onMouseDown={(event) => event.stopPropagation()} // Prevent click from opening the dropdown
                  sx={{
                    "& .MuiChip-deleteIcon": {
                      color: theme.palette.primary.dark,
                    },
                  }}
                />
              ))}
            </Box>
          );
        }}
        MenuProps={MenuProps}
      >
        <MenuItem disabled value="">
          <em>{placeholder}</em>
        </MenuItem>

        {options
          .filter((item) => filterOptions(item))
          .map((item) => (
            <MenuItem
              key={item?.id || item}
              value={item}
              style={getStyles(item, value, theme)}
            >
              {item?.name || item}
            </MenuItem>
          ))}
      </Select>
    </div>
  );
}
