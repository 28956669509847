import { Box, Typography } from '@mui/material'
import classNames from 'classnames'

import css from './styles.module.css'

const PageHeader = ({
  title,
  action,
  filter,
  noBorder,
}) => {
  return (
    <Box className={classNames(css.container, { [css.border]: !noBorder })}>
      
      <Typography variant="h5" sx={{
        marginBottom: '1rem',
      }}>
        {title}
      </Typography>
      {action}
    </Box>
  )
}

export default PageHeader
