import { useMutation } from "@apollo/client";

import { CREATE_ORDER } from "../../../../graphql/order/mutation";
import { GET_ORDERS } from "../../../../graphql/order/query";
import { useNavigate } from "react-router-dom";
import { useNotification } from "../../../../services/hooks/useNotification";

export const useCreateOrder = () => {
  const navigate = useNavigate();
  const { notify } = useNotification();
  const [createOrder, { loading, error }] = useMutation(CREATE_ORDER, {
    refetchQueries: [GET_ORDERS],
    onCompleted: (data) => {
      navigate("/orders");
    },
    onError: (error) => {
      notify(error?.message || "Failed to create order", "error");
    },
  });

  return { createOrder, loading, error };
};
