import { Divider, IconButton } from "@mui/material";
import { Dialog, DialogTitle, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";

import css from "./styles.module.css";

export const ModalDialogTitle = ({ children, onClose, ...other }) => {
  return (
    <DialogTitle
      data-testid="modal-title"
      sx={{
        display: "flex",
      }}
      {...other}
    >
      {children}
      <span style={{ flex: 1 }} />
      {onClose ? (
        <IconButton
          data-testid="modal-dialog-close-btn"
          aria-label="close"
          onClick={(e) => {
            onClose(e, "backdropClick");
          }}
          size="small"
          sx={{
            ml: 2,
            color: "border.main",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const ModalDialog = ({
  dialogTitle,
  children,
  fullScreen = false,
  ...restProps
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isFullScreen = fullScreen || isSmallScreen;

  return (
    <Dialog
      data-testid="modal-view"
      className={css.dialog}
      {...restProps}
      fullScreen={isFullScreen}
      scroll={fullScreen ? "paper" : "body"}
      onClick={(e) => e.stopPropagation()}
    >
      {dialogTitle && (
        <ModalDialogTitle onClose={restProps.onClose}>
          {dialogTitle}
        </ModalDialogTitle>
      )}

      <Divider />
      {children}
    </Dialog>
  );
};

export default ModalDialog;
