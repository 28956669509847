import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import {
  Controller,
  FormProvider,
  useForm,
  useFormState,
} from "react-hook-form";
import { useEffect } from "react";
import useOrderListFilterStore from "../../../store/orderListFIlterSettingsStore";
import VendorAutocomplete from "../../vendor/VendorAutocomplete";
import StoreAutocomplete from "../../common/StoreAutocomplete";
import MultipleSelectChip from "../../common/MultipleSelectChip";

const OrderFilterForm = ({ toggleFilter }) => {
  const { filter, setFilterColumn, setFilterValue, clearFilter } =
    useOrderListFilterStore();

  const formMethods = useForm({
    mode: "onChange",
    defaultValues: {
      store: filter.values.store,
      status: filter.values.status,
    },
  });

  const { control, handleSubmit, reset} = formMethods;
  const { dirtyFields, isValid } = useFormState({ control });

  useEffect(() => {
    // Automatically reset form when filters are cleared
    reset({
      store: filter.values.store,
      status: filter.values.status,
    });
  }, [filter.values, reset]);

  const onSubmit = (data) => {
    const { store, status } = data;
    setFilterValue("store", store);
    setFilterValue("status", status);

    toggleFilter();
  };

  const handleClearFilter = () => {
    clearFilter();
    reset();
  };

  return (
    <Paper elevation={0} variant="outlined" className="filterWrapper">
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container>
            {/* Filter by column selection */}
            <Grid item xs={12} md={3} sx={{ p: 4 }}>
              <FormControl>
                <FormLabel>Filter Orders</FormLabel>
                {Object.keys(filter.columns).map((columnName) => (
                  <Controller
                    key={columnName}
                    name={`columns.${columnName}`}
                    control={control}
                    defaultValue={filter.columns[columnName]}
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            {...field}
                            checked={field.value}
                            onChange={(e) => {
                              const isChecked = e.target.checked;
                              field.onChange(isChecked);
                              setFilterColumn(columnName, isChecked);
                            }}
                          />
                        }
                        label={
                          columnName.charAt(0).toUpperCase() +
                          columnName.slice(1)
                        }
                      />
                    )}
                  />
                ))}
              </FormControl>
            </Grid>

            <Divider orientation="vertical" flexItem />

            {/* Actual filter parameters */}
            <Grid item xs={12} md={8} sx={{ p: 4 }}>
              {/* <FormControl sx={{ width: "100%" }}> */}
                <FormLabel>Parameters</FormLabel>
                <Grid container spacing={2}>
                  {/* Conditionally render the filter value inputs */}
                  {/* {filter.columns.vendor && (
                    <Grid item xs={12} md={6}>
                      <VendorAutocomplete
                        name="vendor"
                        control={control}
                        required={false}
                      />
                    </Grid>
                  )} */}
                  {filter.columns.store && (
                    <Grid item xs={12} md={6}>
                      <StoreAutocomplete control={control} />
                    </Grid>
                  )}

                  {filter.columns.status && (
                    <Grid item xs={12} md={6}>
                      <Controller
                        name="status"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <MultipleSelectChip
                            options={["Pending", "Approved", "Completed"]}
                            label="Status"
                            value={value || []}
                            onChange={onChange}
                          />
                        )}
                      />
                    </Grid>
                  )}
                </Grid>
              {/* </FormControl> */}

              {/* Action buttons */}
              <Grid container spacing={2} sx={{ mt: 3 }}>
                <Grid item>
                  <Button variant="contained" onClick={handleClearFilter}>
                    Clear
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={!isValid}
                  >
                    Apply
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </Paper>
  );
};

export default OrderFilterForm;

