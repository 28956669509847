import {
  DialogActions,
  DialogContent,
  CircularProgress,
  Button,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ModalDialog from "../../common/ModalDialog/index";
import { useForm } from "react-hook-form";
import VendorForm from "./VendorForm";
import { useMutateVendor } from "../hooks/useMutateVendor";

function VendorDetail({ handleClose, defaultValues }) {
  const { mutateVendor, loading: submitting } = useMutateVendor(handleClose);
  const {
    control,
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = useForm({
    defaultValues: defaultValues?.data,
  });

  const submitCallback = handleSubmit(async (data) => {
    //create vendor here
    try {
      await mutateVendor(data, true);
    } catch (e) {
      console.log(e);
    }
  });

  const onSubmit = (e) => {
    e.stopPropagation();
    submitCallback(e);
  };

  return (
    <ModalDialog
      open
      onClose={handleClose}
      dialogTitle={defaultValues.data.name}
      PaperProps={{
        sx: {
          position: "absolute",
          right: 0,
          maxWidth: "30vw !important",
          height: "100vh",
          m: 0,
          borderRadius: 0,
        },
      }}
    >
      <form onSubmit={onSubmit}>
        <DialogContent
          sx={{
            minHeight: "40vh",
          }}
        >
          <VendorForm control={control} />
        </DialogContent>

        <DialogActions
          sx={{
            display: "flex",
            marginRight: 2,
            marginBottom: 2,
          }}
        >
          <Button
            variant="contained"
            data-testid="cancel-btn"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            data-testid="save-btn"
            type="submit"
            variant="contained"
            disabled={!isValid}
            endIcon={
              isSubmitting || submitting ? (
                <CircularProgress size={15} />
              ) : (
                <EditIcon />
              )
            }
            disableElevation
          >
            Submit
          </Button>
        </DialogActions>
      </form>
    </ModalDialog>
  );
}

export default VendorDetail;
