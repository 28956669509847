import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import MultipleSelectChip from "../../../common/MultipleSelectChip";
import { Button, DialogActions, DialogContent, Tooltip } from "@mui/material";
import ModalDialog from "../../../common/ModalDialog";
import { UPDATE_ORDER_STATUS } from "../../../../graphql/order/mutation";
import { useMutation } from "@apollo/client";
import { useNotification } from "../../../../services/hooks/useNotification";

function OrderStatusUpdate({ handleClose, ordersToUpdate }) {
  const { control, getValues } = useFormContext();
  const { notify } = useNotification();

  const [mutateStatus] = useMutation(UPDATE_ORDER_STATUS, {
    onCompleted: (data) => {
      if (data.updateOrderStatus) {
        notify("Orders status updated successfully", "success");
      }
    },
    update: (cache, { variables }) => {
      Object.keys(ordersToUpdate).forEach((id) => {
        const orderRef = `Order:${id}`;
        cache.modify({
          id: orderRef,
          fields: {
            status: () => getValues("status")[0],
          },
        });
      });
    },
  });

  const updateOrders = () => {
    try {
      mutateStatus({
        variables: {
          ids: Object.keys(ordersToUpdate),
          status: getValues("status")[0],
        },
      });
    } catch (error) {
      console.log(error);
    }
    handleClose();
  };
  return (
    <ModalDialog open onClose={handleClose} dialogTitle={"Update Status"}>
      <DialogContent>
        <Controller
          name="status"
          control={control}
          render={({ field: { onChange, value } }) => (
            <MultipleSelectChip
              options={["Pending", "Approved", "Completed"]}
              label="Status"
              value={value || []}
              onChange={onChange}
              placeholder="Select Status"
              multiple={false}
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Tooltip
          title={
            ordersToUpdate.length === 0
              ? "Select orders to update their status"
              : ""
          }
        >
          <span>
            <Button
              disabled={ordersToUpdate.length === 0}
              variant="contained"
              onClick={() => updateOrders()}
            >
              Confirm
            </Button>
          </span>
        </Tooltip>
      </DialogActions>
    </ModalDialog>
  );
}

export default OrderStatusUpdate;
