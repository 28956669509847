import { useMutation } from "@apollo/client";
import { CREATE_ITEM, UPDATE_ITEM } from "../../../graphql/items/mutation";
import { GET_ITEMS } from "../../../graphql/items/query";
import { useNotification } from "../../../services/hooks/useNotification";
import useAuthStore from "../../../store/authStore";

export const useMutateItem = (handleClose) => {
  const { currentStore } = useAuthStore();
  const { notify } = useNotification();

  const [createItem, { loading: createLoading }] = useMutation(CREATE_ITEM, {
    onCompleted: () => {
      handleClose();
      notify("Item added successfully", "success");
    },
    onError: (error) => {
      notify("Failed to add item", "error");
    },
    refetchQueries: [GET_ITEMS],
  });

  const [updateItem, { loading: updateLoading }] = useMutation(UPDATE_ITEM, {
    onCompleted: () => {
      handleClose();
      notify("Item updated successfully", "success");
    },
    onError: (error) => {
      notify("Failed to update item", "error");
    },
    refetchQueries: [GET_ITEMS],
  });

  const mutateItem = async (input, isUpdate = false) => {
    const item = {
      id: input.id,
      name: input.name,
      description: input.description,
      size: input.size,
      strain: input.strain,
      brandId: input.brand,
      category: input.category?.id || input.category,
      storeId: currentStore?.id,
    };

    const mutation = isUpdate ? updateItem : createItem;
    return await mutation({
      variables: { input: item },
    });
  };

  return {
    mutateItem,
    loading: createLoading || updateLoading,
  };
};
