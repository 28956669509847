import { Typography } from "@mui/material";
import DetailModal from "../common/Lists/DetailModal";
function CategoryDetail({ handleClose, defaultValues }) {
  return (
    <DetailModal title={"Category Detail"} handleClose={handleClose}>
      <Typography variant="label">Name: {defaultValues.data.name}</Typography>
      <Typography variant="label">
        Master Category: {defaultValues.data.masterCategory.name}
      </Typography>
    </DetailModal>
  );
}
export default CategoryDetail;
