import { gql } from "@apollo/client";

export const Item = gql`
  fragment Item on Item {
    id
    name
    description
    size
    strain
    brandId
    store {
      id
    }
    category {
      id
      name
    }
    createdAt
  }
`;

export const GET_ITEMS = gql`
  query getItems($filter: ItemFilterInput) {
    items(filter: $filter) {
      ...Item
    }
  }
  ${Item}
`;
