import React, { createContext, useState, useMemo, useCallback } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

export const NotificationContextType = {
  notify: (message, severity) => {},
  closeNotification: () => {},
};

export const NotificationContext = createContext(NotificationContextType);

export default function CustomizedSnackbars({
  message,
  handleClose,
  snackState,
  severity,
}) {
  const handleState = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    handleClose?.();
  };

  return (
    <div>
      <Snackbar open={snackState} autoHideDuration={6000} onClose={handleState}>
        <Alert
          onClose={handleState}
          severity={severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}

export const NotificationProvider = ({ children }) => {
  const [notification, setNotification] = useState(null);

  const notify = useCallback((message, severity) => {
    console.log(message);
    if (typeof message === "string") {
      setNotification({ message, severity, open: true });
    } else {
      setNotification({
        message: message[0]?.message || "Something went wrong",
        severity,
        open: true,
      });
    }
  }, []);

  const handleClose = useCallback(() => {
    setNotification((prev) => prev && { ...prev, open: false });
  }, []);

  const contextValue = useMemo(() => ({ notify }), [notify]);

  return (
    <NotificationContext.Provider value={contextValue}>
      {children}
      {notification && (
        <CustomizedSnackbars
          message={notification.message}
          severity={notification.severity}
          snackState={notification.open}
          handleClose={handleClose}
        />
      )}
    </NotificationContext.Provider>
  );
};
