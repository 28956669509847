import React from "react";
import { DialogActions, DialogContent, List } from "@mui/material";
import ModalDialog from "../../common/ModalDialog/index";

function DetailModal({ title, handleClose, children }) {
  return (
    <ModalDialog
      open
      onClose={handleClose}
      dialogTitle={title}
      PaperProps={{
        sx: {
          position: "absolute",
          right: 0,
          maxWidth: "30vw !important",
          height: "100vh",
          m: 0,
          borderRadius: 0,
        },
      }}
    >
      <DialogContent>
        <List
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          {children}
        </List>
      </DialogContent>

      <DialogActions
        sx={{
          display: "flex",
          margin: 1,
        }}
      ></DialogActions>
    </ModalDialog>
  );
}

export default DetailModal;
