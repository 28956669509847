import React, { useState, useEffect } from "react";
import { useBrands } from "./hooks/useBrands.jsx";
import { Controller, useFormContext } from "react-hook-form";
import { Autocomplete, TextField } from "@mui/material";
import Loader from "../common/Loader.jsx";

function BrandsAutocomplete() {
  const { brands, loading } = useBrands();
  const [options, setOptions] = useState([]);
  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext();

  const brandId = watch("brandId");

  useEffect(() => {
    if (!loading && brands) {
      setOptions(brands);
    }
  }, [brands, loading]);

  return (
    <Controller
      name="brand"
      control={control}
      defaultValue={brandId}
      rules={{
        required: {
          value: true,
          message: "Brand is required*",
        },
      }}
      render={({ field }) => (
        <Autocomplete
          {...field}
          disablePortal
          getOptionLabel={(option) => {
            // Handle both object and ID cases
            if (typeof option === "object") return option.name;
            // Find the vendor by ID and return its name
            return options.find((o) => o.id === option)?.name || "";
          }}
          isOptionEqualToValue={(option, value) => option.id === value}
          options={!loading ? options : []}
          value={field.value || null}
          onChange={(_, data) => field.onChange(data?.id)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Brand"
              error={Boolean(errors.brand)}
              helperText={errors.brand?.message}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? <Loader color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      )}
    />
  );
}

export default BrandsAutocomplete;
