import React from "react";
import { useCategories } from "./hooks/useCategories.jsx";
import AutoComplete from "../common/AutoComplete";
import { useFormContext } from "react-hook-form";

function CategoryAutoComplete() {
  const { categories, loading } = useCategories();
  const { watch } = useFormContext();
  const categoryId = watch("category");

  return (
    <AutoComplete
      loading={loading}
      data={categories}
      label="Category"
      name="category"
      value={categoryId}
      rules={{
        required: {
          value: true,
          message: "*",
        },
      }}
    />
  );
}

export default CategoryAutoComplete;
