import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
} from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import SendIcon from "@mui/icons-material/Send";
import ModalDialog from "../../common/ModalDialog/index";
import { useMutateItem } from "../hooks/useMutateItem";
import ItemForm from "./ItemForm";

function CreateItem({ handleClose, defaultValues, title = "Create Item" }) {
  const { mutateItem, loading } = useMutateItem(handleClose);
  const methods = useForm({
    defaultValues,
    mode: "onChange",
  });

  const { handleSubmit, formState } = methods;

  const submitCallback = handleSubmit(async (data) => {
    //create vendor here
    try {
      await mutateItem(data);
    } catch (e) {
      console.log(e);
    }
  });

  const onSubmit = (e) => {
    e.stopPropagation();
    submitCallback(e);
  };

  return (
    <ModalDialog open onClose={handleClose} dialogTitle={title}>
      <FormProvider {...methods}>
        <form onSubmit={onSubmit}>
          <DialogContent
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <ItemForm control={methods.control} />
          </DialogContent>

          <DialogActions
            sx={{
              display: "flex",
              margin: 2,
            }}
          >
            <Button
              variant="contained"
              data-testid="cancel-btn"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              data-testid="save-btn"
              type="submit"
              variant="contained"
              disabled={!formState.isValid}
              endIcon={
                formState.isSubmitting || loading ? (
                  <CircularProgress size={10} />
                ) : (
                  <SendIcon />
                )
              }
              disableElevation
            >
              Submit
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </ModalDialog>
  );
}

export default CreateItem;
