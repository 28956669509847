import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: ["DM Sans"].join(","),
  },
  palette: {
    primary: {
      main: "#76FC8F", // Green for primary actions
      light: "#B6FFC2", // Light green for secondary actions
      dark: "#008060", // Dark green for disabled actions
      contrastText: "#000000",
    },
    secondary: {
      light: "#FFCC80",
      main: "#1c4549",
      dark: "#388E3C",
      contrastText: "#FFFFFF", // Black text on secondary color
    },
    icon: {
      light: "#FFCC80", // Light orange
      main: "#FF9800", // Orange for secondary actions
      dark: "#F57C00", // Dark orange
      contrastText: "#000000", // Black text on secondary color
    },
    border: {
      light: "#E0E0E0", // Light gray border
      main: "#BDBDBD", // Medium gray border
      dark: "#9E9E9E", // Dark gray border
      contrastText: "#000000", // Black text on border color
    },
    error: {
      light: "#E57373", // Light red
      main: "#F44336", // Red for error states
      dark: "#D32F2F", // Dark red
      contrastText: "#FFFFFF", // White text on error color
    },
    warning: {
      light: "#FFF0E8", // Light yellow
      main: "#FF9800", // Yellow for warning states
      dark: "#F57C00", // Dark yellow
      contrastText: "#F57C00", // Black text on warning color
    },
    info: {
      light: "#64B5F6", // Light blue
      main: "#2196F3", // Blue for informational states
      dark: "#1976D2", // Dark blue
      contrastText: "#FFFFFF", // White text on info color
    },
    success: {
      light: "#E5FFEA", // Light green
      main: "#4CAF50", // Green for success states
      dark: "#388E3C", // Dark green
      contrastText: "#388E3C", // White text on success color
    },
    action: {
      active: "#76FC8F",
      hover: "#76FC8F",
      selected: "#76FC8F",
      disabled: "#635b51", // Gray for disabled actions
      contrastText: "#FFFFFF", // White text on disabled color
      //disabledBackground: "#605c57", // Light orange for disabled actions
    },
    background: {
      default: "#FFFFFF", // Light gray for body background
      paper: "#F5F5F5", // White for card backgrounds
      appbar: "#FFFFFF", // White for appbar backgrounds
    },
    text: {
      primary: "#212121", // Dark text color
      secondary: "#757575", // Gray text color
    },
  },
  components: {
    MuiPaper: {
      defaultProps: {
        elevation: 0, // No shadow for paper components
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "6px", // Rounded corners
          padding: "4px 8px", // Button padding
          textTransform: "none", // No uppercase transformation
        },
        contained: {
          boxShadow: "none", // No shadow for contained buttons
        },
        outlined: {
          border: "1px solid #E0E0E0", // Outlined buttons have borders
          color: "#000000",
          padding: "4px 8px", // Button padding
        },
        "&.Mui-disabled": {
          backgroundColor: "action.disabled", // Gray for disabled actions
          color: "action.contrastText", // White text on disabled color
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: "6px", // Rounded corners
          padding: "4px 8px", // Button padding
          textTransform: "none", // No uppercase transformation
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          padding: "4px", // Padding for grid container
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: "#1C4546", // Side navigation background color
          borderRight: "1px solid #E0E0E0", // Light gray border
          contrastText: "#30727A", // White text on dark background
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "#FFFFFF", // White background for AppBar
          borderBottom: "1px solid #E0E0E0", // Light gray border
          boxShadow: "none", // No shadow for AppBar
          marginBottom: "30px", // AppBar bottom margin
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: "#EEF4F6", // Table header color
          color: "#42787E", // Table header text color
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          backgroundColor: "#ffffff", // Table body color
          border: "none",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: "4px",
          borderBottom: `1px solid #E0E0E0`,
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        boxShadow: "none", // No shadow for table rows
        root: {
          padding: "8px",
          "&:nth-of-type(even)": {
            // backgroundColor: "#F5F5F5", // Light gray for even rows
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          "& .MuiFilledInput-root": {
            backgroundColor: "#FFFFFF", // White background for input fields
            border: "1px solid #E0E0E0", // Light gray border
            borderRadius: "6px",
            "&::before": {
              border: "1px solid red",
              padding: "0px",
            },
            "& .MuiInputBase-hiddenLabel": {
              padding: "4px 8px",
            },
          },
          "& .MuiFilledInput-input": {
            padding: "4px 8px", // Input padding
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        size: "small",
      },
      styleOverrides: {
        root: {
          "& .MuiFilledInput-root": {
            backgroundColor: "#FFFFFF", // White background for input fields
            border: "1px solid #E0E0E0", // Light gray border
            borderRadius: "6px",
          },
        },
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        size: "small",
      },
      styleOverrides: {
        root: {
          "& .MuiFilledInput-root": {
            backgroundColor: "#D9E9ED", // White background for input fields
            border: "1px solid #E0E0E0", // Light gray border
            "&:hover": {
              border: "1px solid #E0E0E0", // Light gray border on hover
              background: "#FFFFFF", // White background on hover
            },
            "&::before": {
              border: "none !important",
              padding: "0px",
            },
          },
          // "& .MuiFilledInput-root::before": {
          //   border: "none",
          //   "&:hover": {
          //     border: "none",
          //   },
          // },
        },
      },
    },
  },
});

export default theme;
