import React from "react";
import { Box } from "@mui/material";
import DetailModal from "../../common/Lists/DetailModal";
import { useForm, FormProvider } from "react-hook-form";
import { Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ItemForm from "./ItemForm";
import { useMutateItem } from "../hooks/useMutateItem";

import { CircularProgress } from "@mui/material";

function ItemDetail({ handleClose, defaultValues }) {
  const { mutateItem, loading: submitting } = useMutateItem(handleClose);
  const methods = useForm({
    defaultValues: defaultValues.data,
  });

  const {
    control,
    handleSubmit,
    formState: { isValid, isDirty, isSubmitting },
  } = methods;

  const submitCallback = handleSubmit(async (data) => {
    try {
      await mutateItem(data, true);
    } catch (e) {
      console.log(e);
    }
  });

  const onSubmit = (e) => {
    e.stopPropagation();
    submitCallback(e);
  };

  return (
    <DetailModal title={defaultValues?.data?.name} handleClose={handleClose}>
      <FormProvider {...methods}>
        <form onSubmit={onSubmit}>
          <ItemForm control={control} />
          <Box
            sx={{ mt: 3, display: "flex", justifyContent: "flex-end", gap: 2 }}
          >
            <Button
              variant="contained"
              data-testid="cancel-btn"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              data-testid="save-btn"
              type="submit"
              variant="contained"
              disabled={!isDirty}
              endIcon={
                isSubmitting ? <CircularProgress size={10} /> : <EditIcon />
              }
              disableElevation
            >
              Submit
            </Button>
          </Box>
        </form>
      </FormProvider>
    </DetailModal>
  );
}

export default ItemDetail;
