import React from "react";
import {
  Popper,
  Paper,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
  ClickAwayListener,
} from "@mui/material";

const ActionModal = ({ open, handleClose, anchorEl, actions }) => {
  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      placement="bottom-start"
      style={{ zIndex: 1300 }}
    >
      <ClickAwayListener onClickAway={handleClose}>
        <Paper elevation={3} sx={{ width: "300px", maxWidth: "100%" }}>
          <List>
            {actions.map((action, index) => (
              <ListItem key={index} disablePadding>
                <ListItemButton
                  onClick={() => {
                    action.action();
                    handleClose();
                  }}
                >
                  <ListItemText
                    primary={action.name}
                    secondary={
                      <Typography variant="body2" color="text.secondary">
                        {action.description}
                      </Typography>
                    }
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Paper>
      </ClickAwayListener>
    </Popper>
  );
};

export default ActionModal;
