import React from "react";
import useAuthStore from "../../store/authStore";
import MultipleSelectChip from "./MultipleSelectChip";
import { Controller } from "react-hook-form";

function StoreAutocomplete({ control }) {
    const { stores } = useAuthStore();

  return (
    <Controller
      name="store"
      control={control}
      render={({ field: { onChange, value } }) => (
        <MultipleSelectChip
          label="Store"
          options={stores}
          value={value || []}
          onChange={onChange}
          // onDelete={(option) => {
          //   const newValue = value.filter((item) => item.id !== option.id);
          //   onChange(newValue);
          // }}
        />
      )}
    />
  );
}

export default StoreAutocomplete;
