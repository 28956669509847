import React from "react";
import { DialogContent } from "@mui/material";
import ModalDialog from "../../common/ModalDialog/index";
import Upload from "../../common/Dropzone/index";

function ImportVendors({ handleClose, defaultValues }) {
  return (
    <ModalDialog
      open
      onClose={() => handleClose()}
      dialogTitle="Import Vendors"
    >
      <DialogContent>
        <Upload onSuccess={() => handleClose()} />
      </DialogContent>
    </ModalDialog>
  );
}

export default ImportVendors;
