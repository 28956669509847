import React from "react";
import Lists from "../../common/Lists";
import useColumnStateStore from "../../../store/columnStateStore";

function OrdersTable({ handleViewDetail, loading, list }) {
  const { columns} = useColumnStateStore();
  return (
    <Lists
      isLoading={loading}
      list={list}
      label="Orders"
      handleViewDetail={handleViewDetail}
      columns={columns.filter((column) => column.selected)}
    />
  );
}

export default OrdersTable;
