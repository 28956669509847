import { Typography, Box } from "@mui/material";
import DataField from "./DataField";
import React from "react";

const DataSection = ({ title, fields, control }) => (
  <Box sx={{ mb: 3, border: "1px solid #ccc", p: 2, borderRadius: 2 }}>
    <Typography variant="h6" sx={{ mb: 2 }}>
      {title}
    </Typography>
    {fields.map((field) =>
      field.component ? (
        typeof field.component === "function" ? (
          <field.component />
        ) : (
          React.cloneElement(field.component)
        )
      ) : (
        <DataField
          key={field.name}
          name={field.name}
          label={field.label}
          control={control}
          rules={field.rules}
        />
      )
    )}
  </Box>
);

export default DataSection;
