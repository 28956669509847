import { useState, useMemo } from "react";
import { useVendors } from "../hooks/useVendors";
import Lists from "../../common/Lists";
import VendorDetail from "./VendorDetail";
import CreateVendor from "./CreateVendor";
import FullPageSpinner from "../../common/FullPage";
import ListHeader from "../../common/Lists/ListsHeader";
import { defaultOpen, ModalType } from "../../../utils/contants/Modal/modal";
import ExportDialog from "../../common/Lists/ExportDialog";
import ImportVendors from "./ImportVendors";
import { FormProvider, useForm } from "react-hook-form";

const coloumnsForCsvExport = ["name", "address"];
const columns = [
  {
    id: "name",
    label: "Name",
  },
  {
    id: "email",
    label: "Email",
  },
  {
    id: "address",
    label: "Address",
    render: (row) => {
      if (row === null) return "No address";
      let country = row?.country;
      let city = row?.city;
      let zipCode = row?.zipCode;
      let street = row?.street;
      return `${street}, ${city}, ${zipCode}, ${country}`;
    },
  },
];

export default function VendorList() {
  const { vendors, loading } = useVendors();
  const [searchQuery, setSearchQuery] = useState("");
  const [defaultValues, setDefaultValues] = useState(undefined);
  const [open, setOpen] = useState(defaultOpen);
  const formMethods = useForm({
    mode: "onChange",
  });

  const filteredEntries = useMemo(() => {
    if (!searchQuery) {
      return vendors;
    }

    const query = searchQuery.toLowerCase();
    return vendors.filter((order) => {
      const { name } = order;

      return name.toLowerCase().includes(query);
    });
  }, [vendors, searchQuery]);

  const handleClose = () => {
    setOpen(defaultOpen);
    setDefaultValues(undefined);
  };
  const handleOpenModal = (type) => () => {
    setOpen((prev) => ({ ...prev, [type]: true }));
  };

  const handleSave = (updatedData) => {
    // Here you would typically send the updated data to your backend
    console.log("Saving updated vendor data:", updatedData);
  };
  const handleOpenModalWithValues = (modal, data) => {
    setDefaultValues(data);
    handleOpenModal(modal)();
  };

  return (
    <>
      <ListHeader
        handleOpenModal={handleOpenModal}
        handleOpenModalWithValues={handleOpenModalWithValues}
        title="Vendors"
        allListItems={vendors}
        searchQuery={searchQuery}
        onSearchQueryChange={setSearchQuery}
      />
      {loading ? (
        <FullPageSpinner />
      ) : (
        <FormProvider {...formMethods}>
          <form>
            <Lists
              handleViewDetail={(data) =>
                handleOpenModalWithValues(ModalType.DETAILS, { data })
              }
              loading={loading}
              list={filteredEntries}
              columns={columns}
              label="Vendors"
            />
          </form>
        </FormProvider>
      )}

      {open[ModalType.ENTRY] && (
        <CreateVendor handleClose={handleClose} defaultValues={defaultValues} />
      )}

      {open[ModalType.DETAILS] && (
        <VendorDetail
          handleClose={handleClose}
          onSave={handleSave}
          defaultValues={defaultValues}
        />
      )}

      {open[ModalType.EXPORT] && (
        <ExportDialog
          handleClose={handleClose}
          defaultValues={defaultValues}
          coloumnsForCsvExport={coloumnsForCsvExport}
          name="Vendors"
        />
      )}

      {open[ModalType.IMPORT] && (
        <ImportVendors
          handleClose={handleClose}
          defaultValues={defaultValues}
        />
      )}
    </>
  );
}
