import { create } from "zustand";
import { persist } from "zustand/middleware";

const useOrderListFilterStore = create(
  persist(
    (set, get) => ({
      filter: {
        // Example structure: Manage both selection status and values
        columns: {
          vendor: false,
          store: false,
          status: false,
        },
        values: {
          vendor: null,
          store: null,
          status: null,
        },
      },
      setFilterColumn: (columnName, isSelected) => {
        const filter = get().filter;
        set({
          filter: {
            ...filter,
            columns: {
              ...filter.columns,
              [columnName]: isSelected,
            },
          },
        });
      },
      setFilterValue: (filterName, value) => {
        const filter = get().filter;
        set({
          filter: {
            ...filter,
            values: {
              ...filter.values,
              [filterName]: value,
            },
          },
        });
      },
      clearFilter: () => {
        set({
          filter: {
            columns: {
              vendor: false,
              store: false,
              status: false,
            },
            values: {
              vendor: null,
              store: null,
              status: null,
            },
          },
        });
      },
    }),
    {
      name: "order-list-filter-storage",
      getStorage: () => localStorage,
      expireIn: 1000 * 60 * 60 * 24, // 24 hours
    }
  )
);

export default useOrderListFilterStore;
